import './sidenav.css'
import {NavLink} from "react-router-dom";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import React, {useState} from "react";
import {sideBarData} from "../../helpers/SideBarHelper";
import {Tooltip} from "@mui/material";

export default function SideBar() {
    const [open, setOpen] = useState(true)
    const toggleOpen = () => {
        setOpen(!open)
    }
    return (
        <div className={open ? 'sidenav' : 'sidenavClosed'}>
            <button className={'menuBtn'} onClick={toggleOpen}>
                {open ? <KeyboardDoubleArrowLeftIcon/> : <KeyboardDoubleArrowRightIcon/>}
            </button>
            {sideBarData.map(item =>
                    <NavLink key={item.id} className={'sideitem'} to={item.link}>
                        {item.icon}
                        <span className={'linkText'}>{item.text}</span>
                    </NavLink>
            )}
        </div>
    )
}