import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {CopyBlock, github, googlecode} from "react-code-blocks";
import {oauthRequests, oauthResponse} from "./GettingStartedHelper";

function CustomTabPanel(props: any) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


export default function GettingStarted() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div>
            <h3>Getting started with COF API</h3>
            <div>
                <p>In order to request the access token you need to get your <b>Client_ID</b> and <b>Client
                    Secret</b>:</p>
                <p>With our credentials in hand, we are ready to request an access token.</p>
                <ul>
                    <li>
                        Send a <b>POST</b> request to the token endpoint URI.
                    </li>
                    <li>
                        Add the Content-Type header set to the application/x-www-form-urlencoded value.
                    </li>
                    <li>
                        Add a HTTP body containing the Client ID and Client Secret, along with the grant_type
                        parameter set to client_credentials.
                    </li>
                </ul>
            </div>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
                    <Tab label="Bash"/>
                    <Tab label="Javascript"/>
                    <Tab label="Python"/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <CopyBlock
                    text={oauthRequests.get("Bash")!!}
                    language={'bash'}
                    showLineNumbers={true}
                    theme={github}
                    codeBlock
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <CopyBlock
                    text={oauthRequests.get("Javascript")!!}
                    language={'javascript'}
                    showLineNumbers={true}
                    theme={github}
                    codeBlock
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <CopyBlock
                    text={oauthRequests.get("Python")!!}
                    language={'python'}
                    showLineNumbers={true}
                    theme={github}
                    codeBlock
                />
            </CustomTabPanel>
            <div>
                <div>
                    <p className={'text-xl-start'}>The response will return an access token valid for 1
                        hour:</p>
                    <CopyBlock
                        text={oauthResponse}
                        language={'json'}
                        showLineNumbers={true}
                        theme={googlecode}
                        wrapLongLines={true}
                    />
                </div>
            </div>
        </div>
    );
}