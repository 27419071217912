import React from 'react'
import {RedocStandalone} from "redoc";
import {useLocation} from "react-router-dom";
import {Alert, AlertTitle} from "@mui/material";

export function TimeseriesPage() {
    const location = useLocation()
    console.log(location.pathname)
    return (<div>
        {location.pathname.indexOf('v1') != -1 &&
            <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                This API version will soon be deprecated. Please use V2 instead.
            </Alert>
        }
        <RedocStandalone
            specUrl={`http://localhost:8080/api${location.pathname}.yaml`}
            options={{
                nativeScrollbars: true,
                theme: {
                    colors: {primary: {main: "blue"}},
                    typography: {
                        fontSize: "14px",
                        code: {
                            fontSize: "14px"
                        }
                    }
                },
                scrollYOffset: 150,
                disableSearch: true,
                hideDownloadButton: true,
                jsonSampleExpandLevel: "all",
                hideSecuritySection: true,
                hideHostname: true,
                showWebhookVerb: false,
                hideSingleRequestSampleTab: true,
                hideRequestPayloadSample: true
            }}
        />
    </div>)
}