import React from 'react';
import './App.css';
import SideBar from "./components/sideBar/SideBar";
import {
    Routes,
    Route,
} from "react-router-dom";
import {TimeseriesPage} from "./components/timeseries/TimeseriesPage";
import {AssetPage} from "./components/asset/AssetPage";
import {LandingPage} from "./components/LandingPage";
import GettingStarted from "./components/gettingStarted/GettingStarted";
function App() {
    return (
            <div className="App">
                <SideBar/>
                <main>
                    <Routes>
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path="/api" element={<GettingStarted/>}/>
                        <Route path="/asset-service-v2" element={<AssetPage/>}/>
                        <Route path="/timeseries-service-v2" element={<TimeseriesPage/>}/>
                        <Route path="/timeseries-service-v1" element={<TimeseriesPage/>}/>
                    </Routes>
                </main>
            </div>
    );
}

export default App;
