import React from 'react'
import {Button, Card, CardActions, CardContent, Chip,} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';


export function LandingPage() {
    return (<div>
        <h3>Integrate your systems with Yara DPP Connected Factory APIs</h3>
        <h4>Explore our APIs</h4>
        <Card sx={{m:3}}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Asset Service V2 <Chip label="New"/>
                </Typography>
                <Typography variant="body2">
                    Using this you can search the assets , create and delete.
                </Typography>
            </CardContent>
            <CardActions>
                <Button href={"/asset-service-v2"} size="small">Learn More</Button>
            </CardActions>
        </Card>
        <Divider/>
        <Card sx={{m:3}}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Time Service V1 <Chip label="Will be deprecated soon."/>
                </Typography>
                <Typography variant="body2">
                    Using these apis you can find information on tags and view datapoints and aggregates.
                    Also you can create new timeseries, post data points for soft sensors.
                    <p>We suggest you migrate over to V2 API instead.</p>
                </Typography>
            </CardContent>
            <CardActions>
                <Button href={"/asset-service-v2"} size="small">Learn More</Button>
            </CardActions>
        </Card>
        <Divider/>
        <Card sx={{m:3}}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Time Service V2 <Chip label="New"/>
                </Typography>
                <Typography variant="body2">
                    <p>Using these apis you can find information on tags and view datapoints and aggregates.</p>
                    <p>This has more functionality than V1 like batch data and aggregates endpoints.</p>
                    <p>Also you can create new timeseries, post data points for soft sensors.</p>
                    <p>Multivariate timeseries metadata and data points .</p>
                </Typography>
            </CardContent>
            <CardActions>
                <Button href={"/asset-service-v2"} size="small">Learn More</Button>
            </CardActions>
        </Card>
    </div>)
}