import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ApiIcon from '@mui/icons-material/Api';

export const sideBarData = [
    {
        id: 0,
        icon: <HomeIcon/>,
        text: "Home",
        link: "/"
    }, {
        id: 1,
        icon: <CodeIcon/>,
        text: "Getting Started",
        link: "/api"
    },
    {
        id: 2,
        icon: <TipsAndUpdatesIcon/>,
        text: "API Updates",
        link: "/updates"
    },
    {
        id: 3,
        icon: <ApiIcon/>,
        text: "Asset Service V2",
        link: "/asset-service-v2"
    },
    {
        id: 4,
        icon: <ApiIcon/>,
        text: "Timeseries Service V1",
        link: "/timeseries-service-v1"
    },
    {
        id: 5,
        icon: <ApiIcon/>,
        text: "Timeseries Service V2",
        link: "/timeseries-service-v2"
    }
]