import React from 'react'
import {RedocStandalone} from "redoc";
export function AssetPage(){

    return (<div>
        <RedocStandalone
            specUrl={`http://localhost:8080/api/asset-service-v2.yaml`}
            options={{
                nativeScrollbars: true,
                theme: {
                    colors: { primary: { main: "blue" } },
                    typography: {
                        fontSize: "14px",
                        code: {
                            fontSize: "14px"
                        }
                    }
                },
                scrollYOffset: 150,
                disableSearch: true,
                hideDownloadButton: true,
                jsonSampleExpandLevel: "all",
                hideSecuritySection: true,
                hideHostname: true,
                showWebhookVerb: false,
                expandDefaultServerVariables:false
            }}
        />
    </div>)
}