export const oauthResponse = `{
"access_token": "BQDBKJ5eo5jxbtpWjVOj7ryS84khybFpP_lTqzV7uV-T_m0cTfwvdn5BnBSKPxKgEb11",
"token_type": "Bearer",
"expires_in": 3600
}`

export const oauthRequests = new Map<string, string>([
    ["Bash", `curl -X POST "https://eu-auth.api.dpp.yara.com/oauth2/token" \\
 -H "Content-Type: application/x-www-form-urlencoded" \\
  -d "grant_type=client_credentials&client_id=your-client-id&client_secret=your-client-secret"`],
    ["Javascript", `async function requestToken() {
  try {
    const response = await fetch('https://eu-auth.api.dpp.yara.com/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'password',
        client_id: clientId,
        client_secret: clientSecret,
      }),
    });

    return await response.json().access_token; 
  } catch (error) {
    throw error;
  }
}`], ["Python", `import requests

def request_token():
    token_endpoint = 'https://eu-auth.api.dpp.yara.com/oauth2/token'  # Replace with your token endpoint
    client_id = 'your_client_id'
    client_secret = 'your_client_secret'

    data = {
        'grant_type': 'password',
        'client_id': client_id,
        'client_secret': client_secret,
    }

    try:
        response = requests.post(token_endpoint, data=data)
        token_data = response.json()
        return token_data['access_token'] 
    except requests.exceptions.RequestException as e:
        raise`]
])

